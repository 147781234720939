import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj07 from "../imgObjs/imgObj07"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Kontakt"
      keywords={[`Hebamme`, `Frankfurt`, `Beratung`, `Geesche Martin`]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj07} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P textAlign="right">
            <em>„Beziehungen sind eine Rutschbahn nach oben.“</em> <br />— Karl
            Farkas
          </P>
        </Box>
      </Flex>
      <H1>Kontakt</H1>
      <P>
        Ich freue mich auf Ihre Mail an{" "}
        <a href="mailto:kontakt@hebamme-in-frankfurt.de">
          kontakt@hebamme-in-frankfurt.de
        </a>{" "}
        oder auf Ihren Anruf an{" "}
        <span style={{ whiteSpace: "nowrap" }}>069–599761</span>.
      </P>
    </Section>
  </Layout>
)

export default IndexPage
